<div class="group-panel cabin-panel">
    <div class="row uncollapse group-header">
        <div class="column small-8 large-9 ">
            <div class="cabin-type">
                <sbw-information-popup [text]="cabinGroup.infoText"></sbw-information-popup>
                <h5>{{cabinGroup.groupName}}</h5>
            </div>
        </div>
        <div class="column small-4 large-3">
            <div class="time">
                <h5>{{formattedDate(this.departureDate)}}</h5>
            </div>
        </div>
    </div>
    <div *ngFor="let cabin of cabinGroup.cabins; let i = index">
        <div *ngFor="let group of formSelections.controls">
            <div *ngIf="cabin.id === group.value.id">
                <sbw-cabins-row 
                    [cabin]="cabin" 
                    [cabinSelection]="group" 
                    [osloFrederikshavnHack]="osloFrederikshavnHack"
                    [jerseyHack]="jerseyHack"
                ></sbw-cabins-row>
            </div>
        </div>
    </div>
</div>
