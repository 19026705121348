import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { LocalDateTime } from 'js-joda'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { ResourceService } from 'app/shared/resources/resource.service'
import { StorageService } from 'app/shared/storage.service'
import { PaymentService } from 'app/payment/payment.service'
import { CookieService } from 'app/shared/cookie.service'
import { SalesChannelService } from 'app/shared/salesChannel/sales-channel.service'
import { IReservation } from 'app/shared/models/reservation.interfaces'
import { IInputCreditCard } from 'app/payment/credit-cards/credit-cards.component'
import { LayoutState } from 'app/shared/layout/layout-state'
import { TrackingAmendmentStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { timer } from 'rxjs'

@Component({
  selector: 'sbw-remaining',
  templateUrl: './remaining.component.html',
  styleUrls: ['./remaining.component.css'],
})
export class RemainingComponent implements OnInit {
  private reservationCode: any
  public reservation: IReservationModel
  public reservationForTracking: IReservation
  public inputCreditCard: IInputCreditCard

  public isOutstandingMode = false
  public dateFormat: string
  private token: string

  remainingForm: FormGroup

  constructor(
    private activatedRoute: ActivatedRoute,
    private reservationService: ReservationService,
    private resourceService: ResourceService,
    private storageService: StorageService,
    private layoutState: LayoutState,
    private paymentService: PaymentService,
    private cookieService: CookieService,
    private salesChannelService: SalesChannelService,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    const { token, reservationcode } = this.activatedRoute.snapshot.queryParams
    this.remainingForm = new FormGroup({})
    if (token && reservationcode) {
      this.isOutstandingMode = true
      this.token = token
      this.reservationCode = reservationcode
      this.paymentService.getReservation(this.reservationCode, token).subscribe((res) => {
        this.reservationForTracking = res
        this.reservation = this.convert(res)
        this.resourceService.loadResourcesPromise(res.routeCode, res.locale, ['Payment', 'Amendment', 'Common', 'Currency', 'Passengers']).then(() => {
          this.resourceService.get('SHORT_DATE_TEMPLATE').subscribe((s) => (this.dateFormat = s))
          this.cookieService.setReservationToken(token) // to be removed when orca handles authorization
          this.cookieService.setOutstandingAuthToken(token)
          this.cookieService.setCookie('sbRouteCode', res.routeCode)
          this.cookieService.setCookie('sbw_Locale', res.locale)
          this.loadCreditCards(res.salesOwnerId)
          this.trackingService.trackAmendment(TrackingAmendmentStep.DEPOSIT, false, res)
          this.layoutState.setIsContentLoaded(true)
        })
      })
    } else {
      this.isOutstandingMode = false
      this.reservationCode = this.activatedRoute.snapshot.params['reservationCode']
      this.reservationService.getReservation(this.reservationCode).subscribe((res) => {
        this.reservationForTracking = res
        this.reservation = this.convert(res)
        this.cookieService.setCookie('sbRouteCode', res.routeCode)
        this.resourceService.loadResourcesPromise(res.routeCode, res.locale, ['Passengers', 'Amendment', 'Payment', 'Common', 'Currency']).then(() => {
          this.resourceService.get('SHORT_DATE_TEMPLATE').subscribe((s) => (this.dateFormat = s))
          const authToken = sessionStorage.getItem('pax_Token')
          this.cookieService.setAuthorizationToken(authToken)
          this.loadCreditCards(res.salesOwnerId)
          this.trackingService.trackAmendment(TrackingAmendmentStep.PAYMENT, false, res)
          this.layoutState.setIsContentLoaded(true)
        })
      })
    }
  }

  private loadCreditCards(salesOwnerId: number): void {
    this.paymentService.getCreditCards(salesOwnerId, this.salesChannelService.getCode()).subscribe((creditCards) => {
      this.inputCreditCard = {
        form: this.remainingForm,
        creditCards: creditCards,
        creditCardCode: null,
      }
    })
  }

  private convert(reservationData: IReservation): IReservationModel {
    const outboundLeg = <ILegModel>{
      departureDate: reservationData.departures[0].departureDateTime,
      description: reservationData.departures[0].routeName,
    }
    const returnLeg = !reservationData.departures[1]
      ? null
      : <ILegModel>{ departureDate: reservationData.departures[1].departureDateTime, description: reservationData.departures[1].routeName }
    let name = `${reservationData.contact.firstName} ${reservationData.contact.lastName}`
    if (reservationData.contact.title) {
      name = `${reservationData.contact.title} ${name}`
    }
    return <IReservationModel>{
      customerName: name,
      code: reservationData.reservationCode,
      remainingAmount: reservationData.remainingAmount,
      currencyCode: reservationData.currencyCode,
      outboundLeg,
      returnLeg,
    }
  }

  public postForm() {
    this.layoutState.setIsContentLoaded(false)

    if (this.isOutstandingMode) {
      this.paymentService
        .postOutstandingPayment(this.remainingForm.get('creditCards.creditCard').value, this.reservationCode, this.token, location.href)
        .subscribe((data) => {
          if (data && data.paymentPageUrl) {
            this.cookieService.setOutstandingConfirmPage()
            location.href = data.paymentPageUrl
          } else {
            console.log(`ERROR: Couldn't redirect. Got return data - ${data}`)
            this.layoutState.setIsContentLoaded(true)
          }
          this.trackingService.saveAmendmentTrackingData(this.reservationForTracking)
        })
    } else {
      this.paymentService
        .postAmendmentPayment(this.remainingForm.get('creditCards.creditCard').value, this.reservationCode, location.href)
        .subscribe((data) => {
          if (data && data.paymentPageUrl) {
            this.clearStorageForReservation()
            this.cookieService.setAmendmentConfirmPage()
            // used to force a reservation into the browser without cache headers because we know the reservation will now change
            this.reservationService.getReservationNoCache(this.reservationCode).subscribe((r) => (location.href = data.paymentPageUrl))
          } else {
            console.log(`ERROR: Couldn't redirect. Got return data - ${data}`)
            this.layoutState.setIsContentLoaded(true)
          }
          this.trackingService.saveAmendmentTrackingData(this.reservationForTracking)
        })
    }
  }

  private clearStorageForReservation() {
    this.storageService.removeItem(`BNO_${this.reservationCode}_CMDS`)
  }
}

interface ILegModel {
  departureDate: LocalDateTime
  description: string
}

interface IReservationModel {
  customerName: string
  code: string
  remainingAmount: number
  currencyCode: string
  outboundLeg: ILegModel
  returnLeg: ILegModel
}
