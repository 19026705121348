import { Component, OnInit, Input } from '@angular/core'
import { ICabin } from 'app/cabins/cabins.interfaces'
import { SelectItem } from 'app/shared/select/select.models'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'sbw-cabins-row',
    templateUrl: './cabins-row.component.html',
    styleUrls: ['./cabins-row.component.css']
})
export class CabinsRowComponent implements OnInit {

    @Input() cabin: ICabin
    @Input() cabinSelection: FormGroup
    @Input() osloFrederikshavnHack?: IOsloFrederikshavnHack
    @Input() jerseyHack?: IJerseyHack

    private items: SelectItem[] = []

    constructor() { }

    ngOnInit() {
        let maximumCount: number = this.cabin.capacity < this.cabin.maximumCount ? this.cabin.capacity : this.cabin.maximumCount
        if (this.cabin.capacity == 0 && this.cabin.hasCapacity) maximumCount = this.cabin.maximumCount // this rule applies if customer has bought this item, i.e. capacity will be 0 allthough it claims there is capacity
        this.populateCountDropDownValues(this.cabin.minimumCount, maximumCount)
    }

    public populateCountDropDownValues(min: number, max: number) {
        if (min > max) throw new Error('Min > max')
        if (this.osloFrederikshavnHack){
            const { cabinGroupName, routeCode, numberOfAdultsAndChildren } = this.osloFrederikshavnHack
            if (/^deck ticket$/i.test(cabinGroupName) && (/^osfh$/i.test(routeCode) || /^fhos$/i.test(routeCode))) {
                max = numberOfAdultsAndChildren // Hack for Oslo Frederikshavn
            } 
        }

        //Hack to make it possible to book reclining seat for infants on Jersey routes
        if(this.jerseyHack){
            const { cabinGroupName, routeCode, numberOfAdultsAndChildren } = this.jerseyHack
            if(jerseyRoutesWithrRecliningSeats.includes(routeCode) && cabinGroupName.toLowerCase().includes("reclining")){
                max = numberOfAdultsAndChildren
            }
        }

        for (let i = min || 0; i <= max; i++) {
            this.items.push(new SelectItem(i.toString(), i.toString()));
        }
    }

    public checkForSelectedRow() {
        return this.cabinSelection.value.count > 0
    }
}

export interface IOsloFrederikshavnHack {
    routeCode: string
    numberOfAdultsAndChildren: number // infants omitted 
    cabinGroupName: string
}

export interface IJerseyHack {
    routeCode: string
    numberOfAdultsAndChildren: number // infants omitted 
    cabinGroupName?: string
}

export const jerseyRoutesWithrRecliningSeats = ['PMSH', 'SHPM']