<sbw-layout [isAgent]="true" [backButtonVisible]="false" type="Amendment" [continueButtonVisible]="false">
  <div class="sbw_pagewbox">
    <div class="row uncollapse meals-container">
      <div class="row uncollapse login-container">
        <div class="column small-12 xlarge-4 login-box">
          <div class="row">
            <div data-e2e="agentSendResetPassword">
            <form (submit)="handleResetPasswordSubmit()" [formGroup]="agentResetPasswordForm">
              <h3>{{ 'AGENT_RESET_PASSWORD_TITLE' | resource }}</h3>
              <div *ngIf="requestIdValidated" class="row">
                <div class="column small-12">
                  <div class="row">
                    <div class="column small-12 label">
                      <label>{{ 'AGENT_PASSWORD' | resource }}</label>
                    </div>
                    <div class="column small-12 field">
                      <input
                        class="text-box single-line"
                        id="agentPassword"
                        type="password"
                        value=""
                        formControlName="password"
                      />
                      <span
                        class="field-validation-error"
                        *ngIf="validationError && agentResetPasswordForm.controls.password?.errors && agentResetPasswordForm.controls.password?.value === ''"
                        >{{ 'AGENT_EMPTY_PASSWORD_FIELD' | resource }}</span
                      >
                      <span
                        class="field-validation-error"
                        *ngIf="validationError && agentResetPasswordForm.controls.password?.errors && agentResetPasswordForm.controls.password?.value !== ''"
                        >{{ 'AGENT_MIN_MAX_PASSWORD_FIELD' | resource }}</span
                      >
                    </div>
                    <div class="column small-12 label">
                      <label>{{ 'AGENT_REENTER_NEW_PASSWORD' | resource }}</label>
                    </div>
                    <div class="column small-12 field">
                      <input
                        class="text-box single-line"
                        id="agentReenterPassword"
                        type="password"
                        value=""
                        formControlName="reenterPassword"
                      />
                      <span
                        class="field-validation-error"
                        *ngIf="validationError && agentResetPasswordForm.controls.reenterPassword?.errors && agentResetPasswordForm.controls.reenterPassword?.value === ''"
                        >{{ 'AGENT_EMPTY_PASSWORD_FIELD' | resource }}</span
                      >
                      <span
                      class="field-validation-error"
                      *ngIf="validationError && agentResetPasswordForm.controls.reenterPassword?.errors && agentResetPasswordForm.controls.reenterPassword?.value !== ''"
                      >{{ 'AGENT_MIN_MAX_PASSWORD_FIELD' | resource }}</span
                    >
                      <span
                      [ngClass]="{ 'field-validation-error': agentPasswordNotSame, hide: !agentPasswordNotSame }"
                      >{{ 'AGENT_PASSWORD_MISMATCH' | resource }}</span
                    >
                    </div>
                  </div>
                </div>
                <div class="column small-12 field submit">
                  <button type="submit" class="preferred-action" [buttonState]="submitButtonState" [defaultState]="submitButtonState">
                    {{ 'ACCOUNT_CONFIRM_PASSWORD' | resource }}
                  </button>
                  <span
                    [ngClass]="{ 'field-validation-ok': agentPasswordResetSuccess, hide: !agentPasswordResetSuccess }"
                    >{{ 'AGENT_SUCCESSFUL_PWD_RESET_MESSAGE' | resource }}</span
                  >
                </div>
              </div>
              <div *ngIf="!requestIdValidated" class="row">
                <div class="column small-12 field submit">
                  <span
                    [ngClass]="{ 'field-validation-error': !requestIdValidated, hide: requestIdValidated }"
                    >{{ 'AGENT_RESET_PASSWORD_REQUEST_EXPIRED' | resource }}</span
                  >
                </div>
                <div class="column small-12 field submit">
                  <button type="button" (click)="handleInitiatePasswordReset()" class="secondary-action" [buttonState]="submitButtonState" [defaultState]="submitButtonState">
                    {{ 'AGENT_RESEND_EMAIL' | resource }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</sbw-layout>
