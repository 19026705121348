import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { LoginResult, LoginError, ILoginClassicWireModel, IIdentificationResult } from './agent-login.models'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { StatelessHttpService } from 'app/shared/stateless-http.service'

@Injectable()
export class AgentLoginService {
  constructor(
    private statelessHttpService: StatelessHttpService,
    public hybridService: HybridService
  ) {}

  loginClassic(credentials): Observable<any> {
    return this.statelessHttpService.post(false, 'agent/login', credentials)
  }

  loginBearer(username: string, password: string, agentName: string, salesOwnerId: string): Observable<any> {
    let body = {
      grant_type: 'password',
      username: username,
      password: password,
      reference: agentName,
      salesOwnerId: salesOwnerId,
    }
    return this.statelessHttpService.post(false, 'token', body)
  }

  getIdentity(bearer: string): Observable<IIdentificationResult> {
    let headers = { Authorization: `Bearer ${bearer}` }
    return this.statelessHttpService.get<IIdentificationResult>(false, 'agent/whoami', { headers })
  }

  login(username: string, password: string, agentName: string, stayLoggedIn: boolean, salesOwnerId: string): Observable<LoginResult> {
    const credentials: ILoginClassicWireModel = {
      username: username,
      password: password,
      reference: agentName,
      stayLoggedIn: stayLoggedIn,
      salesOwnerId: salesOwnerId,
    }
    // Call LoginBearer first since it doesn't have side effects.
    return new Observable((s) =>
      this.loginBearer(username, password, agentName, salesOwnerId).subscribe(
        (bearerResponse) => {
          this.getIdentity(bearerResponse.access_token).subscribe((identity) => {
            this.loginClassic(credentials).subscribe(
              () => s.next(new LoginResult(bearerResponse.access_token, identity.travelAgentCode)),
              (error: any) => {
                if (error?.status === 302) {
                  this.redirectToAgentResetPassword('forceResetPassword')
                  return
                }
                else s.error(new LoginError('5'))
              }
            )
          })
        },
        (bearerError) => s.error(new LoginError(bearerError.error.error))
      )
    )
  }

  public redirectToAgent(): void {
    this.hybridService.changeWindowLocation('agent')
  }

  public redirectToAgentResetPassword(queryParam?: string): void {
    this.hybridService.changeWindowLocation(queryParam ? `agent/account/reset?${queryParam}` : 'agent/account/reset')
  }

  public checkUserExist(username: string, salesOwnerId: string) {
    const body = {
      username,
      salesOwnerId,
    }

    return this.statelessHttpService.post(false, 'agentpassword/validateagent', body)
  }

  public resendPassword(username: string, salesOwnerId: string) {
    const body = {
      username,
      salesOwnerId,
    }
    return this.statelessHttpService.post(false, 'agentpassword/resend', body)
  }
}
