import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

import { ResourceService } from '../../shared/resources/resource.service'
import { AgentLoginService } from './agent-login.service'
import { QueryModel, LoginResult, LoginError } from './agent-login.models'
import { CookieService } from '../../shared/cookie.service'
import { ButtonStateEnum } from '../../shared/directives/button-state-directive'
import { SbEvent } from '../../shared/sb-event.emitter'
import { LayoutState } from 'app/shared/layout/layout-state'
import { AuthService } from 'app/auth/auth.service'

@Component({
  selector: 'agent-login',
  templateUrl: './agent-login.component.html',
})
export class AgentLoginComponent implements OnInit {
  private queryModel: QueryModel
  public password: string
  public username: string
  public agentReference: string
  public stayLoggedIn: boolean

  // Validation
  public agentForm: FormGroup
  public validationError = false

  private passwordForm = new FormControl('', Validators.required)
  private abtaForm = new FormControl('', Validators.required)
  private referenceForm = new FormControl('', Validators.required)
  private stayLoggedInForm = new FormControl('')

  wrongUsername: boolean
  passwordWasResent: boolean
  passwordWasResentFailed: boolean

  hasError: boolean
  errorCode: number

  submitButtonState: ButtonStateEnum
  resetPwdButtonState: ButtonStateEnum

  // Fields validation checking
  agentAbtaValid: boolean = true
  agentPasswordValid: boolean = true
  agentNameValid: boolean = true

  constructor(
    private resourceService: ResourceService,
    private agentLoginService: AgentLoginService,
    private route: ActivatedRoute,
    private layoutState: LayoutState,
    private cookieService: CookieService,
    private authService: AuthService,
    private events: SbEvent
  ) {
    this.agentForm = new FormGroup({
      passwordForm: this.passwordForm,
      abtaForm: this.abtaForm,
      referenceForm: this.referenceForm,
      stayLoggedInForm: this.stayLoggedInForm,
    })
  }

  ngOnInit() {
    this.changeButtonStates(ButtonStateEnum.Activated, ButtonStateEnum.Activated)
    this.queryModel = this.getQueryModel()
    this.resourceService
      .loadResourcesPromise('Default', this.queryModel.locale, ['Agent', 'MenuAgent', 'AgentSearch', 'AgentProduct', 'AgentHome'])
      .then(() => {
        // Remove error code on field change
        this.abtaForm.valueChanges.subscribe((value) => {
          if (value?.length > 0) this.validationError = false
          this.errorCode = 0
        })

        this.passwordForm.valueChanges.subscribe((value) => {
          this.errorCode = 0
        })
        this.layoutState.setIsContentLoaded(true)
      })
  }

  private getQueryModel(): QueryModel {
    let queryParams = this.route.snapshot.queryParams

    let salesOwner = this.getSalesOwner(queryParams)

    let locale = this.getLocale(queryParams)

    return new QueryModel(salesOwner, locale)
  }

  private getSalesOwner(queryParams): string {
    let salesOwner = queryParams['salesowner']

    if (typeof salesOwner === 'undefined') {
      salesOwner = this.cookieService.getCookie('sbw_SalesOwner')
    }

    return salesOwner
  }

  private getLocale(queryParams): string {
    let locale = queryParams['locale']

    if (typeof locale === 'undefined') {
      locale = this.cookieService.getCookie('sbw_Locale')
    }

    return locale
  }

  private changeButtonStates(submitButtonState: ButtonStateEnum, resetButtonState: ButtonStateEnum) {
    this.submitButtonState = submitButtonState
    this.resetPwdButtonState = resetButtonState
  }

  handleSubmit() {
    if (this.agentForm.valid) {
      this.agentAbtaValid = true
      this.agentPasswordValid = true
      this.agentNameValid = true
      this.changeButtonStates(ButtonStateEnum.Spinner, ButtonStateEnum.Disabled)

      this.authenticate(this.username, this.password, this.agentReference, this.stayLoggedIn, this.queryModel.salesOwner)
    } else {
      // Hide required validation message
      this.agentAbtaValid = false
      this.agentPasswordValid = false
      this.agentNameValid = false
    }
  }

  private authenticate(username: string, password: string, agentReference: string, stayLoggedIn: boolean, salesOwner: string) {
    this.hasError = false
    this.errorCode = 0

    this.agentLoginService.login(username, password, agentReference, stayLoggedIn, salesOwner).subscribe(
      (result: LoginResult) => {
        sessionStorage.setItem('pax_Token', result.bearerToken)
        localStorage.setItem('sbw_AgentGenericId', result.agentCode)
        this.cookieService.setAuthType('agent')
        this.agentLoginService.redirectToAgent()
      },
      (err: LoginError) => {
        this.changeButtonStates(ButtonStateEnum.Activated, ButtonStateEnum.Activated)
        console.log('custom error', err)
        this.hasError = true
        this.errorCode = err.sbwError
      }
    )
  }

  handleResetPassword() {
    this.agentLoginService.redirectToAgentResetPassword()
  }
}
