import { Component, OnInit, Input } from "@angular/core";
import { ICabinDeparture } from "app/cabins/cabins.interfaces";
import { FormGroup } from "@angular/forms";
import { IJerseyHack, IOsloFrederikshavnHack } from "../cabins-row/cabins-row.component";

@Component({
    selector: "sbw-cabins-selector",
    templateUrl: "./cabins-selector.component.html",
    styleUrls: ["./cabins-selector.component.css"],
})
export class CabinsSelectorComponent {
    @Input() formDepartureGroup: FormGroup;
    @Input() formSelections: FormGroup;
    @Input() content: any;
    @Input() osloFrederikshavnHack?: IOsloFrederikshavnHack;
    @Input() jerseyHack?: IJerseyHack;

    get departure(): ICabinDeparture {
        return <ICabinDeparture>this.formDepartureGroup.value;
    }

    constructor() {}
}
