<sbw-layout
    [backButtonVisible]="true"
    [quoteModel]="quoteData"
    [quoteShowTotalPrice]="true"
    [quoteShowDepartureTime]="true"
    [form]="flowForm"
    quoteStep="Cabins"
    [continueButtonResource]="noCabinsSelected ? 'CONTINUE_WITHOUT_CABIN' : 'CONTINUE'"
    (continueEvent)="continue()"
    (backEvent)="navigateBack()"
    >
    <form [formGroup]="flowForm" novalidate>
        <div class="sbw_pagebox">
            <div class="row uncollapse cabin-fares-container">
                <h1>{{'CABIN_TITLE' | resource}}</h1>
                <div class="row">
                    <div class="column small-12 large-10 sbw_legend gutter" [innerHTML]="'CABIN_FLOW_TEASER' | resource"></div>
                </div>
                <div *ngIf="errorList.length > 0" class="row">
                    <div class="column small-12 gutter">
                        <sbw-validation-summary [errorList]="errorList"></sbw-validation-summary>
                    </div>
                </div>
                <sbw-cabins-selector *ngIf="cabinsLoaded" [formDepartureGroup]="outboundDepartureGroup" [formSelections]="outboundSelections" [content]="flowCabinsContent" [jerseyHack]="jerseyHackOut"></sbw-cabins-selector>
                <sbw-cabins-selector *ngIf="!isOneway && cabinsLoaded" [formDepartureGroup]="returnDepartureGroup" [formSelections]="returnSelections" [content]="flowCabinsContent" [jerseyHack]="jerseyHackRet"></sbw-cabins-selector>
            </div>
        </div>
    </form>
</sbw-layout>
