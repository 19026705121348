import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { ResourceService } from '../../shared/resources/resource.service'
import { ButtonStateEnum } from '../../shared/directives/button-state-directive'
import { LayoutState } from 'app/shared/layout/layout-state'
import { AgentRegisterService } from '../register/agent-register.service'
import { zip } from 'rxjs'
import { StatelessHttpService } from 'app/shared/stateless-http.service'
import { ActivatedRoute } from '@angular/router'
import { CookieService } from 'app/shared/cookie.service'

@Component({
  selector: 'agent-reset',
  templateUrl: './agent-reset.component.html',
})
export class AgentResetComponent implements OnInit {
  public market: string
  public agentReference: string
  
  public username: string
  public markets: Market[]

  // Validation
  public agentResetForm: FormGroup
  public agentResetPasswordForm: FormGroup
  public validationError = false

  public showPasswordResetMessage: boolean = false

  private abtaForm = new FormControl('', Validators.required)
  private marketForm = new FormControl('', Validators.required)

  hasError: boolean
  errorCode: number

  submitButtonState: ButtonStateEnum

  // Fields validation checking
  agentAbtaValid: boolean = true
  agentMarketValid: boolean = true
  agentResetPasswordInitiated: boolean = false
  agentResetPasswordFailed: boolean = false
  
  salesOwners: { id; name }[] = []

  constructor(
    private resourceService: ResourceService,
    private layoutState: LayoutState,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private registerService: AgentRegisterService,
    private statelessHttpService: StatelessHttpService,
    private cookieService: CookieService
  ) {
    this.agentResetForm = new FormGroup({
      marketForm: this.marketForm,
      abtaForm: this.abtaForm,
    })

    this.agentResetPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(5)]],
      reenterPassword: ['', [Validators.required, Validators.minLength(5), passwordMatchValidator]]
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.showPasswordResetMessage = params['forceResetPassword'] !== undefined
    });

    this.changeButtonStates(ButtonStateEnum.Activated)

    // Remove error code on field change
    this.abtaForm.valueChanges.subscribe((value) => {
      if (value?.length > 0) this.validationError = false
      this.errorCode = 0
    })

    this.resourceService
      .loadResourcesPromise('Default', 'en', ['Agent', 'MenuAgent', 'AgentSearch', 'AgentProduct', 'AgentHome', 'Account'])
      .then(async () => {
        const [s] = await zip(this.registerService.getSalesOwners()).toPromise()
        this.markets = s
        this.layoutState.setIsContentLoaded(true)
      })
  }

  public handleSubmit(): void {
    this.agentResetPasswordInitiated = false
    this.agentResetPasswordFailed = false
    
    if (this.agentResetForm.valid) {
      this.changeButtonStates(ButtonStateEnum.Spinner)
      
      this.agentAbtaValid = true
      this.agentMarketValid = true

      this.initiateSendResetEmail(this.abtaForm.value, this.marketForm.value).subscribe(response => {
        this.changeButtonStates(ButtonStateEnum.Activated)
        this.agentResetPasswordInitiated = true
      })
    } else {
      this.validationError = true
      
      // Hide required validation message
      this.agentAbtaValid = false
    }
  }

  private changeButtonStates(submitButtonState: ButtonStateEnum) {
    this.submitButtonState = submitButtonState
  }

  public initiateSendResetEmail(agentId: string, salesOwnerId: string) {
    const body = {
      agentId,
      salesOwnerId,
      locale: this.cookieService.getCookie('sbw_Locale')
    }
    return this.statelessHttpService.post(false, 'agent/sendresetpasswordemail', body)
  }
}

interface Market {
  id: string
  name: string
}

function passwordMatchValidator(form: FormGroup) {
  const password = form.get('password');
  const reenterPassword = form.get('reenterPassword');
  return password && reenterPassword && password.value === reenterPassword.value ? null : { mismatch: true };
}