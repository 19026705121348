<div
    class="row leg-container"
    data-route-code="departure.routeCode"
    data-route-name="departure.routeName"
>
    <div class="column gutter leg-container-data no-padding-mobile">
        <div class="leg-header padding-mobile">
            <h2>
                {{ departure.routeName }}
            </h2>
            <span
                *ngIf="departure.ferryName"
                class="icon-ship leg-ferry half-gutter"
                >{{ departure.ferryName }}</span
            >
            <p
                *ngIf="
                    (!departure.cabinGroups ||
                        departure.cabinGroups.length == 0) &&
                    content
                "
                class="noCabins"
            >
                {{ content.noCabinsAvailable }}
            </p>
        </div>
        <div *ngFor="let group of departure.cabinGroups">
            <sbw-cabins-category-group
                [formSelections]="formSelections"
                [cabinGroup]="group"
                [departureDate]="departure.departureDate"
                [osloFrederikshavnHack]="osloFrederikshavnHack"
                [jerseyHack]="jerseyHack"
            ></sbw-cabins-category-group>
        </div>
    </div>
</div>
