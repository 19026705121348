<sbw-popup *ngIf="disabledLinksPopup" [showPopup]="amendmentDisabledPopupVisible" [closable]="true" (actionButtonClick)="amendmentDisabledPopupVisible=false" [header]="disabledLinksPopup?.heading">
    {{disabledLinksPopup.text}}
</sbw-popup>

<div class="links-list container-surrounding">
    <ul class="row">
        <li *ngIf="isShortSea" class="column small-12 nopadding">
            <a id="amend-fares" class="link--uppercase other-changed" *ngIf="hasOtherChangesThanFares" (click)="showAmendmentDisabledPopup()">{{'CHANGE_DEPARTURE_DATE' | resource}} </a>
            <a id="amend-fares" class="link--uppercase" *ngIf="!hasOtherChangesThanFares" [routerLink]="['fares']">{{'CHANGE_DEPARTURE_DATE' | resource}} <img *ngIf="!hasFares" class="arrow" src="/_ng/booking/assets/chevron_right.svg"></a>
            <span *ngIf="hasFares" class="icon-checkmark edited"></span>
        </li>
        <li *ngIf="!isShortSea" class="column small-12 nopadding">
            <a id="amend-cabins" class="link--uppercase other-changed" *ngIf="hasFares" (click)="showAmendmentDisabledPopup()">{{'CHANGE_CABINS' | resource}} </a>
            <a id="amend-cabins" class="link--uppercase" *ngIf="!hasFares" [routerLink]="['cabins']">{{'CHANGE_CABINS' | resource}} <img *ngIf="!hasCabins" class="arrow" src="/_ng/booking/assets/chevron_right.svg"></a>
            <span *ngIf="hasCabins" class="icon-checkmark edited"></span>
        </li>
        <li class="column small-12 nopadding">
            <a id="amend-passengers" class="link--uppercase other-changed" *ngIf="hasFares" (click)="showAmendmentDisabledPopup()">{{'CHANGE_PASSENGERS' | resource}} </a>
            <a id="amend-passengers" class="link--uppercase" *ngIf="!hasFares" [routerLink]="['passengers']">{{'CHANGE_PASSENGERS' | resource}} <img *ngIf="!hasPassengers" class="arrow" src="/_ng/booking/assets/chevron_right.svg"></a>
            <span *ngIf="hasPassengers" class="icon-checkmark edited"></span>
        </li>
        <li *ngIf="!isShortSea && !noMealsToAmend" class="column small-12 nopadding">
            <a id="amend-meals" class="link--uppercase other-changed" *ngIf="hasFares" (click)="showAmendmentDisabledPopup()">{{'CHANGE_MEALS' | resource}} </a>
            <a id="amend-meals" class="link--uppercase" *ngIf="!hasFares" [routerLink]="['meals']">{{'CHANGE_MEALS' | resource}} <img *ngIf="!hasMeals" class="arrow" src="/_ng/booking/assets/chevron_right.svg"></a>
            <span *ngIf="hasMeals" class="icon-checkmark edited"></span>
        </li>
        <!-- <li class="column small-12">
            <a id="amend-allocations" class="link--uppercase" [routerLink]="['cabin-allocations']">{{'CHANGE_CABIN_ALLOCATION' | resource}} <img *ngIf="!hasCabinAllocations" class="arrow" src="/_ng/booking/assets/chevron_right.svg"></a>
            <span *ngIf="hasCabinAllocations" class="icon-checkmark edited right"></span>
        </li> -->
        <li class="column small-12 nopadding">
            <a id="amend-tpa" class="link--uppercase other-changed" *ngIf="hasFares" (click)="showAmendmentDisabledPopup()">{{'CHANGE_THIRD_PARTY_ARRANGEMENTS' | resource}} </a>
            <a id="amend-tpa" class="link--uppercase" *ngIf="!hasFares" [routerLink]="['tpas']">{{'CHANGE_THIRD_PARTY_ARRANGEMENTS' | resource}} <img *ngIf="!hasTpas" class="arrow" src="/_ng/booking/assets/chevron_right.svg"></a>
            <span *ngIf="hasTpas" class="icon-checkmark edited"></span>
        </li>
        <li class="column small-12 nopadding">
            <a id="amend-vehicle" class="link--uppercase other-changed" *ngIf="hasFares" (click)="showAmendmentDisabledPopup()">{{'CHANGE_VEHICLES' | resource}} </a>
            <a id="amend-vehicle" class="link--uppercase" *ngIf="!hasFares" [routerLink]="['vehicles']">{{'CHANGE_VEHICLES' | resource}} <img *ngIf="!hasVehicles" class="arrow" src="/_ng/booking/assets/chevron_right.svg"></a>
            <span *ngIf="hasVehicles" class="icon-checkmark edited"></span>
        </li>
    </ul>
</div>
