import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ResourceService } from '../../shared/resources/resource.service'
import { ButtonStateEnum } from '../../shared/directives/button-state-directive'
import { LayoutState } from 'app/shared/layout/layout-state'
import { StatelessHttpService } from 'app/shared/stateless-http.service'
import { HybridService } from 'app/shared/hybrid/hybrid.service'

@Component({
  selector: 'agent-reset-password',
  templateUrl: './agent-reset-password.component.html',
})
export class AgentResetPasswordComponent implements OnInit {
  public market: string
  public agentReference: string
  public requestId: string
  public requestIdValidated: boolean = false
  
  public username: string
  public markets: Market[]

  // Validation
  public agentResetForm: FormGroup
  public agentResetPasswordForm: FormGroup
  public validationError = false

  private passwordForm = new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(52)])
  private reenterPasswordForm = new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(52)])

  hasError: boolean
  errorCode: number

  submitButtonState: ButtonStateEnum

  // Field password validation checking
  agentPasswordNotSame: boolean = false
  agentPasswordRequired: boolean = false
  agentPasswordMinMaxRequired: boolean = false
  agentPasswordResetSuccess: boolean = false

  salesOwners: { id; name }[] = []

  constructor(
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private layoutState: LayoutState,
    private fb: FormBuilder,
    private statelessHttpService: StatelessHttpService,
    public hybridService: HybridService
  ) {
    this.agentResetPasswordForm = this.fb.group({
      password: this.passwordForm,
      reenterPassword: this.reenterPasswordForm
    }, { validators: passwordMatchValidator });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.requestId = params['requestId']
    });

    this.passwordForm.valueChanges.subscribe(() => {
      this.agentPasswordNotSame = false
    })

    this.reenterPasswordForm.valueChanges.subscribe(() => {
      this.agentPasswordNotSame = false
    })

    this.changeButtonStates(ButtonStateEnum.Activated)

    this.resourceService
      .loadResourcesPromise('Default', 'en', ['Agent', 'MenuAgent', 'AgentSearch', 'AgentProduct', 'AgentHome', 'Account'])
      .then(async () => {
        this.validateRequest(this.requestId).subscribe((response: any) => {
          this.requestIdValidated = response?.success
          this.layoutState.setIsContentLoaded(true)
        });
      })
  }

  public handleResetPasswordSubmit(): void {
    this.agentPasswordResetSuccess = false
    this.agentPasswordNotSame = false
    
    if (this.agentResetPasswordForm.valid) {
      this.initiateResetPassword(this.passwordForm.value).subscribe((response: any) => {
        this.changeButtonStates(ButtonStateEnum.Activated)
        this.agentPasswordResetSuccess = true

        // If valid/success we navigate to login
        setTimeout(() => {
          this.hybridService.changeWindowLocation('agent/account/login')
        }, 3000)
      })
    } else {
      this.validationError = true

      if (this.passwordForm.errors?.minLength || this.reenterPasswordForm.errors?.minLength) {
        this.agentPasswordMinMaxRequired = true
        return
      }

      if (this.agentResetPasswordForm?.errors?.mismatch) {
        this.agentPasswordNotSame = true
        return
      }
    }
  } 

  public handleInitiatePasswordReset(): void {
    this.hybridService.changeWindowLocation('agent/account/reset')
  }

  private changeButtonStates(submitButtonState: ButtonStateEnum) {
    this.submitButtonState = submitButtonState
  }

  public validateRequest(requestId: string) {
    return this.statelessHttpService.get(false, `agent/validateresetpassword?requestId=${requestId}`)
  }

  public initiateResetPassword(password: string) {
    const body = {
      requestId: this.requestId,
      PlainTextPassword: password
    }
    return this.statelessHttpService.post(false, 'agent/resetthepassword', body)
  }
}

interface Market {
  id: string
  name: string
}

function passwordMatchValidator(form: FormGroup) {
  const password = form.get('password');
  const reenterPassword = form.get('reenterPassword');
  return password && reenterPassword && password.value === reenterPassword.value ? null : { mismatch: true };
}