import { Component, OnInit, Input } from '@angular/core'
import { ICabinGroup } from 'app/cabins/cabins.interfaces'
import { FormGroup } from '@angular/forms'
import moment from 'moment'
import { IJerseyHack, IOsloFrederikshavnHack } from '../cabins-row/cabins-row.component'

@Component({
    selector: 'sbw-cabins-category-group',
    templateUrl: './cabins-category-group.component.html',
    styleUrls: ['./cabins-category-group.component.css']
})
export class CabinsCategoryGroupComponent implements OnInit {

    @Input() cabinGroup: ICabinGroup
    @Input() departureDate: string;
    @Input() formSelections: FormGroup;
    @Input() osloFrederikshavnHack?: IOsloFrederikshavnHack
    @Input() jerseyHack?: IJerseyHack
    
    constructor() { }

    ngOnInit() {
        if (this.osloFrederikshavnHack) {
            this.osloFrederikshavnHack = {...this.osloFrederikshavnHack, cabinGroupName: this.cabinGroup.groupName}
        }
        if (this.jerseyHack) {
            this.jerseyHack = {...this.jerseyHack, cabinGroupName: this.cabinGroup.groupName}
        }
    }

    public formattedDate(date) {
        return moment(date).format('dddd DD/MM - HH:mm')
    }
}
