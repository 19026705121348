import { Component, NgZone, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms'
import { zip } from 'rxjs'

import { ICabinWire, ICabinDeparture, ICabinSelection, ICabinDepartureSelection } from 'app/cabins/cabins.interfaces'
import { IReservation } from 'app/shared/models/reservation.interfaces'
import { ReservationService } from 'app/shared/reservation/reservation.service'
import { ResourceService } from 'app/shared/resources/resource.service'
import { AmendService } from 'app/shared/reservation/amend.service'
import { CabinsService } from 'app/cabins/cabins.service'
import { SnapshotService } from 'app/shared/snapshot.service'
import { LayoutState } from '../../shared/layout/layout-state'
import { TrackingService } from '../../shared/tracking/tracking.service'
import { TrackingAmendmentStep } from '../../shared/tracking/tracking-wire.interfaces'
import { hashObject } from 'app/shared/utils'
import { LoggingService } from 'app/shared/logging.service'
import { ContentfulClientApiFast } from 'app/shared/resources/contentfulClientApiFast'
import { IJerseyHack, IOsloFrederikshavnHack, jerseyRoutesWithrRecliningSeats } from '../cabins-row/cabins-row.component'
import { AuthService } from 'app/auth/auth.service'
import { LocaleService } from 'app/shared/locale-service'
import { CookieService } from 'app/shared/cookie.service'

@Component({
  selector: 'sbw-amendment-cabins',
  templateUrl: './amendment-cabins.component.html',
  styleUrls: ['./amendment-cabins.component.css'],
})
export class AmendmentCabinsComponent implements OnInit {
  public reservationCode: string
  public reservation: IReservation
  // public amendmentsForm: FormGroup = new FormGroup({})
  public amendmentsForm: FormGroup
  public cabinWire: ICabinWire
  public errors: string[] = []
  public isOneway = true
  public departures: ICabinDeparture[]
  public isAmendmentCompatible = false
  public departureSelections: ICabinDepartureSelection[]
  public isAgent: boolean
  public cabinsChanged: boolean = false
  private initialMd5
  private selections
  public amendmentCabinsContent?: any
  public osloFrederikshavnHack: IOsloFrederikshavnHack
  public jerseyHack: IJerseyHack

  get outboundDepartureGroup(): FormGroup {
    return this.amendmentsForm.get('outbound') as FormGroup
  }

  get returnDepartureGroup(): FormGroup {
    return this.amendmentsForm.get('return') as FormGroup
  }

  get outboundSelections(): FormGroup {
    return this.amendmentsForm.get('outboundSelections') as FormGroup
  }

  get returnSelections(): FormGroup {
    return this.amendmentsForm.get('returnSelections') as FormGroup
  }

  constructor(
    private route: ActivatedRoute,
    private reservationService: ReservationService,
    private resourceService: ResourceService,
    private cabinsService: CabinsService,
    private title: Title,
    private fb: FormBuilder,
    private amendService: AmendService,
    private snapshotService: SnapshotService,
    private router: Router,
    private layoutState: LayoutState,
    private loggingService: LoggingService,
    private trackingService: TrackingService,
    private contentful: ContentfulClientApiFast,
    private authService: AuthService,
    private zone: NgZone,
    private localeService: LocaleService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.isAgent = localStorage.getItem('sbw_AgentGenericId') ? true : false
    let authType = this.cookieService.getAuthType()

    let locale = this.localeService.getLocale()

    if ((authType === 'agent' && this.isAgent) || this.authService.isLoggedIn()) {
      this.getData()
    }
  }

  logCabinCapacityIncompatible() {
    if (!this.isAmendmentCompatible) {
      this.loggingService.logWarn({ message: 'Cabin amendment was not compatible' })
    }
  }

  public continue() {
    this.layoutState.setIsContinueLoading(true)
    const dryRun = true
    let departureSelections: ICabinDepartureSelection[] = []
    let outboundDepartureSelections = <ICabinSelection[]>this.amendmentsForm.get('outboundSelections').value
    departureSelections.push({
      departureId: this.outboundDepartureGroup.value.departureId,
      selections: outboundDepartureSelections,
    })
    if (!this.isOneway) {
      let returnDepartureSelections = <ICabinSelection[]>this.amendmentsForm.get('returnSelections').value
      departureSelections.push({
        departureId: this.returnDepartureGroup.value.departureId,
        selections: returnDepartureSelections,
      })
    }

    this.amendService.changeCabins(this.reservationCode, departureSelections, dryRun).subscribe((response) => {
      this.layoutState.setIsContinueLoading(false)

      if (response.errorMsgs && response.errorMsgs.length) {
        this.errors = response.errorMsgs.map((m) => m.message)
        this.layoutState.setIsContentLoaded(true)
        return
      }

      this.snapshotService.setSnapshot(this.reservationCode, { cabins: departureSelections })
      this.navigateBack()
    })
  }

  public cabinsChangedHandler() {
    this.selections = {
      outboundSelections: this.mapSelections(this.outboundSelections.value),
      returnSelections: this.mapSelections(this.returnSelections.value),
    }
    this.cabinsChanged = hashObject(this.selections) !== this.initialMd5
  }

  mapSelections(departureSelections: ICabinSelection[]) {
    if (!departureSelections) return null
    return departureSelections.map((d) => <ICabinSelection>{ id: d.id, count: parseInt(d.count.toString()) })
  }

  navigateBack() {
    this.router.navigate(['/amendment/booking', this.reservationCode])
  }

  anySelectedValidator() {
    return (c: FormArray): { [key: string]: any } =>
      c.controls.map((t) => t.value.count).filter((t) => t > 0).length > 0 ? null : { anySelectedValidator: { valid: false } }
  }

  public buildForm(): void {
    let { departures, departureSelections } = this.cabinWire
    this.departures = departures
    this.isAmendmentCompatible = departures.every((x) => x.isAmendmentCompatible)

    const formControlOutboundDeparture = new FormControl(this.departures[0])
    const formControlReturnDeparture = new FormControl(this.departures[1])
    const formControlOutboundSelections = departureSelections[0].selections.map((s) => this.factorySelectionFormGroup(s))
    const formControlReturnSelections =
      departureSelections[1] && departureSelections[1].selections.map((s) => this.factorySelectionFormGroup(s))

    this.amendmentsForm = this.fb.group({
      outbound: formControlOutboundDeparture,
      return: formControlReturnDeparture,
      outboundSelections: this.fb.array(formControlOutboundSelections, this.anySelectedValidator()),
      returnSelections: this.fb.array(formControlReturnSelections || [], this.anySelectedValidator()),
    })

    this.isOneway = departureSelections.length === 1
    if (this.isOneway) {
      this.amendmentsForm.get('returnSelections').disable()
    }

    if (!this.isAmendmentCompatible) {
      this.resourceService.get('AMENDMENT_CABIN_INCOMPATIBLE').subscribe((s) => (this.errors = [s]))
    }

    this.amendmentsForm.valueChanges.subscribe(() => this.cabinsChangedHandler())
  }

  private factorySelectionFormGroup(selection: ICabinSelection) {
    return this.fb.group({
      id: [selection.id],
      count: [selection.count],
    })
  }

  private getData() {
    this.reservationCode = this.route.snapshot.params['reservationCode']
    let reservation$ = this.reservationService.getReservation(this.reservationCode)
    let cabins$ = this.cabinsService.getCabinsFromReservation(this.reservationCode)

    zip(reservation$, cabins$).subscribe((resArray) => {
      const [reservation, cabins] = resArray

      this.resourceService
        .loadResourcesPromise(
          reservation.routeCode,
          reservation.locale,
          ['Common', 'Amendment', 'Account', 'Currency', 'Quote', 'CabinFares', 'Cabin'].concat(this.isAgent ? ['Agent', 'MenuAgent'] : [])
        )
        .then(() => {
          this.resourceService.get('CABIN_TITLE', false).subscribe((s) => this.title.setTitle(s))

          // 0 - reservation
          this.reservation = reservation

          // 1 - cabins
          this.cabinWire = cabins
          let snapshot = this.snapshotService.getSnapshot(this.reservationCode)
          if (snapshot && snapshot.cabins) {
            this.cabinWire.departureSelections = snapshot.cabins
          }
          this.amendmentsForm = new FormGroup({})
          this.buildForm()
          this.selections = {
            outboundSelections: this.mapSelections(this.outboundSelections.value),
            returnSelections: this.mapSelections(this.returnSelections.value),
          }
          this.initialMd5 = hashObject(this.selections)
          this.trackingService.trackAmendment(TrackingAmendmentStep.CABINS, false, reservation)
          this.logCabinCapacityIncompatible()

          if (/^osfh$/i.test(this.reservation.routeCode) || /^fhos$/i.test(this.reservation.routeCode)) {
            this.osloFrederikshavnHack = {
              routeCode: this.reservation.routeCode,
              numberOfAdultsAndChildren: this.reservation.passengers.filter(
                (x) => x.customerCategory === 'ADULT' || x.customerCategory === 'CHILD'
              ).length,
            } as IOsloFrederikshavnHack
          }

          if(jerseyRoutesWithrRecliningSeats.includes(this.reservation.routeCode)){
            this.jerseyHack = {
                routeCode: this.reservation.routeCode,
                numberOfAdultsAndChildren: this.reservation.passengers.length,
            } as IJerseyHack
          }
          this.layoutState.setIsContentLoaded(true)
        })
    })
    this.contentful.getEntries<any>('flowCabin').subscribe((s) => (this.amendmentCabinsContent = s.items[0].fields))
  }
}
