import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Location } from '@angular/common'

@Injectable()
export class HybridService {
  private supportedAngularRoutes = {
    '': true,
    travel: true,
    'cabin-fares': true,
    fares: true,
    cabins: true,
    meals: true,
    tpa: true,
    passengers: true,
    'payment/agentpayment/newbookingpayment': true,
    confirmation: false, // We set this to false, as agent finalize still does not work using angular routes.
    'agent/account/login': true,
    'agent/account/reset-password': true,
    agent: true,
    newbookingpayment: true,
    'amendment/bookings': true,
  }

  constructor(private router: Router, private location: Location) {}

  public changeWindowLocation(url: string) {
    if (url.match('^https?://')) window.location.href = url
    else if (this.supportedAngularRoutes[url]) {
      this.router.navigate([url])
    } else window.location.href = url
  }

  public replaceWindowLocation(url: string) {
    window.location.replace(url)
  }

  public login(returnUrl?: string) {
    let returnTo = this.location.prepareExternalUrl(returnUrl || window.location.pathname)
    window.location.href = `/_mvc/booking/account/login?returnUrl=${returnTo}`
  }

  public forceLogin(returnUrl?: string) {
    let returnTo = this.location.prepareExternalUrl(returnUrl || window.location.pathname)
    window.location.href = `/_mvc/booking/account/forcelogin?returnUrl=${returnTo}`
  }

  public agentLogin() {
    this.changeWindowLocation('agent/account/login')
  }

  public agentLogout(returnUrl: string) {
    this.changeWindowLocation(`/agent/account/logout?returnUrl=${returnUrl}`)
  }
}
