<sbw-layout [isAgent]="true" [backButtonVisible]="false" type="Amendment" [continueButtonVisible]="false">
  <div class="sbw_pagewbox">
    <div class="row uncollapse meals-container">
      <div class="row uncollapse login-container">
        <div class="column small-12 xlarge-4 login-box">
          <div class="row">
            <div *ngIf="markets && markets.length > 0" data-e2e="agentSendResetPassword">
            <form (submit)="handleSubmit()" [formGroup]="agentResetForm">
              <h3>{{ 'AGENT_RESET_PASSWORD_TITLE' | resource }}</h3>
              <div class="row">
                <div class="column small-12">
                  <div class="row">
                    <div class="column small-12 field">
                      <span
                      class="field-validation-note"
                      *ngIf="showPasswordResetMessage"
                      >{{ 'AGENT_RESET_PASSWORD_REQUIRED_MESSAGE' | resource }}</span
                    >
                    </div>
                    <div class="column small-12 label">
                      <label for="LoginForm.AccountNumber">{{ 'AGENT_ACCOUNT_NUMBER' | resource }}</label>
                    </div>
                    <div class="column small-12 field">
                      <input
                        class="text-box single-line"
                        id="agentUsername"
                        type="text"
                        value=""
                        formControlName="abtaForm"
                        [(ngModel)]="username"
                      />
                      <span
                        class="field-validation-error"
                        *ngIf="validationError && agentResetForm.controls.abtaForm?.errors"
                        >{{ 'AGENT_EMPTY_ACCOUNT_FIELD' | resource }}</span
                      >
                    </div>
                    <div class="column small-12 label">
                      <label for="LoginForm.AccountNumber">{{ 'MARKET' | resource }}</label>
                    </div>
                    <div class="column small-12 field">
                      <select formControlName="marketForm">
                        <option [value]="">-</option>
                        <option *ngFor="let market of markets" [value]="market.id">{{market.name}}</option>
                      </select>
                     <span *ngIf="validationError && agentResetForm.controls.marketForm?.errors" class="field-validation-error">{{'VALIDATION_MARKET_EMPTY' | resource}}</span>
                    </div>
                  </div>
                </div>
                <div class="column small-12 field submit">
                  <button type="submit" class="preferred-action" [buttonState]="submitButtonState" [defaultState]="submitButtonState">
                    {{ 'AGENT_RESET_PASSWORD' | resource }}
                  </button>
                  <span
                    [ngClass]="{ 'field-validation-error': agentResetPasswordFailed, hide: !agentResetPasswordFailed }"
                    >{{ 'AGENT_RESET_PASSWORD_FAILED' | resource }}</span
                  >
                  <span
                    [ngClass]="{ 'field-validation-ok': agentResetPasswordInitiated, hide: !agentResetPasswordInitiated }"
                    data-valmsg-for="LoginForm.ForgotPassword"
                    data-valmsg-replace="true"
                    >{{ 'AGENT_SUCCESSFUL_PWD_RESEND_MESSAGE' | resource }}</span
                  >
                </div>
              </div>
            </form>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</sbw-layout>
