import { Component, OnInit, Input } from '@angular/core';
import { SnapshotService, ISnapshot } from 'app/shared/snapshot.service'
import { IReservation } from 'app/shared/models/reservation.interfaces'
import { hashObject, flatMap } from 'app/shared/utils'
import { arrayObjectComparerMatcher } from 'app/shared/utils'
import { TpaType } from 'app/tpa/tpa.enums'

@Component({
    selector: 'sbw-amendment-links',
    templateUrl: './amendment-links.component.html',
    styleUrls: ['./amendment-links.component.css']
})
export class AmendmentLinksComponent implements OnInit {

    @Input() reservation: IReservation
    @Input() disabledLinksPopup: string

    private snapshots: ISnapshot
    public amendmentDisabledPopupVisible: boolean

    constructor(private snapshotService: SnapshotService) { }

    ngOnInit() {
        let snapshots = this.snapshotService.getSnapshot(this.reservation.reservationCode)
        this.snapshots = snapshots || {}
    }
    public showAmendmentDisabledPopup() {
        this.amendmentDisabledPopupVisible = true
    }

    public get hasCabins(): boolean {
        let ssCabins: any = this.snapshots.cabins || []
        let ssCabinsSelections: IIdAndCount[] = ssCabins.reduce((acc, current) => acc.concat(current.selections), [])
        let cabins = (this.reservation.isMiniCruise ? this.reservation.departures[0].cabins : flatMap(this.reservation.departures, x => x.cabins))
            .map(m => (<IIdAndCount>{ id: m.id, count: m.count }))
        let result = ssCabins.length !== 0 && arrayObjectComparerMatcher<IIdAndCount>(
            ssCabinsSelections,
            cabins,
            (a, b) => a.id === b.id && +a.count === +b.count,
            x => +x.count === 0)
        return result
    }

    public get hasPassengers(): boolean {
        let ssPassengers = this.snapshots.passengers || []
        return ssPassengers.length !== 0 && this.reservation.passengers.reduce((acc, current) => {
            if (acc) { return acc }
            let ssPax = ssPassengers.find(s => s.id === current.id)
            return ssPax && hashObject(ssPax) !== hashObject(current)
        }, false)
    }


    public get hasMeals(): boolean {
        let ssMeals = this.snapshots.meals && this.snapshots.meals.patch || []
        return ssMeals.length !== 0 && this.reservation.departures.reduce((acc, current) => {
            if (acc) { return true }
            let ssMealf = ssMeals.find(m => m.departureId === current.departureId)
            if (!ssMealf) { return false }
            let ssMeal = ssMealf.selectedMeals.map(m => m.mealId.toLowerCase())
            return ssMeal && hashObject(ssMeal) !== hashObject(flatMap(current.rooms, x => x.roomArrangements).map(x => x.id.toLowerCase()))
        }, false)
    }

    public get hasTpas(): boolean {
        let ssTpas = this.snapshots.tpas || []
        let mappedSsTpas = ssTpas.filter(f => f.tpaType === TpaType.Tpa).map(m => (<IIdAndCount>{ id: `${m.id}|${m.optionId}`, count: m.count }))
        let mappedTpas = flatMap(this.reservation.arrangements, x => x.arrangementAsts.map(m => (<IIdAndCount>{ id: `${m.id}|${m.optionId}`, count: m.count })))
        let hasTpasChanges = this.checkTpasHelper(mappedSsTpas, mappedTpas, TpaType.Tpa)
        let mappedSsVouchers = ssTpas.filter(f => f.tpaType === TpaType.Voucher).map(m => (<IIdAndCount>{ id: m.id, count: m.count }))
        let mappedVouchers = flatMap(flatMap(this.reservation.departures, x => x.voucherArrangements), x => x.voucherAsts).map(a => (<IIdAndCount>{ id: a.id, count: a.count }))
        let hasVoucherChanges = this.checkTpasHelper(mappedSsVouchers, mappedVouchers, TpaType.Voucher)
        return hasTpasChanges || hasVoucherChanges
    }

    private checkTpasHelper(mappedTpas: IIdAndCount[], fromReservartion: IIdAndCount[], type: TpaType): boolean {
        if (mappedTpas && mappedTpas.length === 0) { return false }
        return mappedTpas.length !== 0 && arrayObjectComparerMatcher<IIdAndCount>(
            fromReservartion,
            mappedTpas,
            (a, b) => a.id === b.id && +a.count === +b.count,
            x => +x.count === 0)
    }

    public get hasVehicles(): boolean {
        if (this.snapshots.vehicles == null) { return false }

        let snapshotVehicles = this.snapshots.vehicles.selections
            .map(v => (<IVehiclesAndTrailers>{
                vehicleId: v != null && v.vehicleId != null ? v.vehicleId : null,
                vehicleRegistrationNumber: v != null && v.vehicleRegistrationNumber != null ? v.vehicleRegistrationNumber : null,
                trailerId: v != null && v.trailerId != null ? v.trailerId : null,
                trailerRegistrationNumber: v != null && v.trailerRegistrationNumber != null ? v.trailerRegistrationNumber : null
            }))
        let reservationVehicles = this.reservation.departures[0].vehicles
            .map(v => (<IVehiclesAndTrailers>{
                vehicleId: v != null && v.id != null ? v.id : null,
                vehicleRegistrationNumber: v != null && v.registrationNumber != null ? v.registrationNumber : null,
                trailerId: v != null && v.trailer && v.trailer.registrationNumber != null != null ? v.trailer.id : null,
                trailerRegistrationNumber: v != null && v.trailer != null && v.trailer.registrationNumber != null ? v.trailer.registrationNumber : null
            }));
        let result = arrayObjectComparerMatcher<IVehiclesAndTrailers>(
            reservationVehicles,
            snapshotVehicles,
            (a, b) => a.vehicleId === b.vehicleId &&
                a.vehicleRegistrationNumber === b.vehicleRegistrationNumber &&
                a.trailerId === b.trailerId &&
                a.trailerRegistrationNumber === b.trailerRegistrationNumber)
        return result
    }

    public get hasCabinAllocations(): boolean {
        let allocations = this.snapshots.cabinAllocations || []
        return allocations.length > 0 ? true : false;
    }

    public get hasFares(): boolean {
        if (this.snapshots.departures == null) { return false }
        let snapshotDepartures = this.snapshots.departures
        if (this.snapshots.departures.out && this.snapshots.departures.out.id !== this.reservation.departures[0].departureId) { return true }
        if (this.snapshots.departures.return && this.reservation.departures.length === 2 && this.snapshots.departures.return.id !== this.reservation.departures[1].departureId) { return true }
        return false
    }

    public get isShortSea() {
        const noAmendmentForTheseRoutes = ['DVDK', 'DKDV', 'DVCA', 'CADV', 'SMSH', 'SHSM', 'SHPO', 'POSH']
        return noAmendmentForTheseRoutes.indexOf(this.reservation.routeCode) !== -1
    }

    public get hasOtherChangesThanFares(): boolean {
        return this.hasCabins || this.hasPassengers || this.hasMeals || this.hasTpas || this.hasVehicles
    }

    public get noMealsToAmend(): boolean {
        const noAmendmentForTheseRoutes = ["smsh", "shsm", "shpm", "pmsh", "shpo", "posh"]
        return noAmendmentForTheseRoutes.includes(this.reservation.routeCode.toLocaleLowerCase())
    }

}

interface IIdAndCount {
    id: string
    count: number
}

interface IVehiclesAndTrailers {
    vehicleId: string
    vehicleRegistrationNumber: string
    trailerId: string
    trailerRegistrationNumber: string
}
