import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AgentLoginComponent } from "./login/agent-login.component";
import { AgentLoginService } from "./login/agent-login.service";
import { SharedModule } from "../shared";
import { UIModule } from "app/shared/UI.module";
import { AgentLogoutComponent } from "./logout/agent-logout.component";
import { AgentProfileComponent } from "./profile/agent-profile.component";
import { AgentProfileService } from "./profile/agent-profile.service";
import { AgentSearchComponent } from "./search/agent-search.component";
import { AgentSearchService } from "./search/agent-search.service";
import { AgentAuthGuard } from 'app/shared/guards/agent-auth.guard';
import { AgentRegisterComponent } from './register/agent-register.component';
import { AgentRegisterService } from './register/agent-register.service';
import { AgentResetComponent } from "./reset/agent-reset.component";
import { AgentResetPasswordComponent } from "./reset/agent-reset-password.component";

const appRoutes: Routes = [
    { path: "agent/account/login", component: AgentLoginComponent },
    { path: "Agent/Account/LogIn", component: AgentLoginComponent },
    { path: "agent/account/reset", component: AgentResetComponent },
    { path: "agent/account/reset-password", component: AgentResetPasswordComponent },
    { path: "agent/account/_logout", component: AgentLogoutComponent },
    { path: "agent/login", component: AgentLoginComponent },
    { path: "Agent/Login", component: AgentLoginComponent },
    { path: "agent/profile", component: AgentProfileComponent, canActivate: [AgentAuthGuard] },
    { path: "Agent/Profile", component: AgentProfileComponent, canActivate: [AgentAuthGuard] },
    { path: "agent/bookings", component: AgentSearchComponent, canActivate: [AgentAuthGuard] },
    { path: "Agent/bookings", component: AgentSearchComponent, canActivate: [AgentAuthGuard] },
    { path: "agent/account/register", component: AgentRegisterComponent },
    { path: "Agent/Account/Register", component: AgentRegisterComponent },
];

@NgModule({
    imports: [
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes),
        SharedModule,
        UIModule,
    ],
    declarations: [
        AgentLoginComponent,
        AgentLogoutComponent,
        AgentProfileComponent,
        AgentSearchComponent,
        AgentResetComponent,
        AgentResetPasswordComponent,
        AgentRegisterComponent,
    ],
    providers: [AgentLoginService, AgentProfileService, AgentSearchService, AgentRegisterService],
})
export class AgentModule { }
